@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./fonts/Montserrat-Bold.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('./fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat-Light';
  src: url('./fonts/Montserrat-Light.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat-Medium';
  src: url('./fonts/Montserrat-Medium.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'styled-react-icons';
  src: url('./fonts/styled-react-icons.ttf') format('truetype'),
    url('./fonts/styled-react-icons.woff') format('woff'),
    url('./fonts/styled-react-icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

* {
  outline: none;
  box-sizing: border-box;
}
